import Link from 'next/link'
import MedShrLogo from '../../ui/medshr-logo'
import MedShrLogoText from '../../ui/medshr-logo-text'
import { DevMenu } from './DevMenu'

export const Footer = () => (
  <footer className="site-footer fs-unmask">
    <div className="wrapper gutters">
      <div className="site-footer-menu x-large">
        <ul className="list-bare site-footer-menu__list site-footer-menu__list--pages columns-flex-10 columns-flex-12-lap">
          <li className="site-footer__logo site-footer-menu__label">
            <Link href="/">
              <span className="site-footer__logo-link">
                <MedShrLogo width={30} />
                <MedShrLogoText />
              </span>
            </Link>
          </li>
          <li>
            <Link href="/pages/about">About us</Link>
          </li>
          <li>
            <Link href="/team">Our team</Link>
          </li>
          <li>
            <a href="https://careers.medshr.net/" target="_blank">
              Careers
            </a>
          </li>
          <li>
            <Link href="/team/partners">Partners</Link>
          </li>
          <li>
            <Link href="/pages/medshr-case-studies">Case studies</Link>
          </li>
          <li>
            <Link href="https://medshr.com/" target="_blank">
              Partner with us
            </Link>
          </li>
        </ul>
      </div>
      <div className="site-footer-menu x-large">
        <ul className="list-bare site-footer-menu__list site-footer-menu__list--pages columns-flex-10 columns-flex-12-lap">
          <li className="site-footer-menu__label">Contact us</li>
          <li>
            <a href="mailto:contact@medshr.net">contact@medshr.net</a>
          </li>
        </ul>
      </div>
      <div className="site-footer-menu x-large">
        <ul className="list-bare site-footer-menu__list columns-flex-10 columns-flex-12-lap">
          <li className="site-footer-menu__label">Social</li>
          <li>
            <a href="https://facebook.com/medshronline" target="_blank">
              <img
                src="https://static.medshr.net/lander/images/social-fb.svg"
                width={16}
                height={16}
                alt="Connect with MedShr on Facebook"
              />
            </a>
          </li>
          <li>
            <a href="https://instagram.com/medshronline" target="_blank">
              <img
                src="https://static.medshr.net/lander/images/social-instagram.svg"
                width={16}
                height={16}
                alt="Connect with MedShr on Instagram"
              />
            </a>
          </li>
          <li>
            <a href="https://uk.linkedin.com/company/medshr" target="_blank">
              <img
                src="https://static.medshr.net/lander/images/social-linkedin.svg"
                width={16}
                height={16}
                alt="Connect with MedShr on LinkedIn"
              />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/medshronline" target="_blank">
              <img
                src="https://static.medshr.net/lander/images/social-x.svg"
                width={16}
                height={16}
                alt="Follow MedShr on X"
              />
            </a>
          </li>
          <li>
            <a href="https://youtube.com/@medshr7041" target="_blank">
              <img
                src="https://static.medshr.net/lander/images/social-youtube.svg"
                width={16}
                height={16}
                alt="Subscribe to MedShr's YouTube channel"
              />
            </a>
          </li>
        </ul>
      </div>
      <p className="site-footer__get-apps">
        <span className="">
          Available on{' '}
          <span className="site-footer__get-apps__platforms">
            Web, Android, iPhone and iPad
          </span>
        </span>
        <span className="app-download-links fs-unmask">
          <a
            className="app-download-android"
            href="https://medshr.it/play"
            target="_blank"
          >
            <img
              alt="Get it on Google Play"
              src="/assets/2.241.44-d737bb726516a61c354d826a3ea9ab16e0ac4dfc/app/styles/images/google_play_badge.svg"
              width="96"
              height="29"
            />
          </a>
          <a
            className="app-download-ios"
            href="https://medshr.it/app-store"
            target="_blank"
          >
            <img
              alt="Download on the App Store"
              src="/assets/2.241.44-d737bb726516a61c354d826a3ea9ab16e0ac4dfc/app/styles/images/app-store-badge.svg"
              width="87"
              height="29"
            />
          </a>
        </span>
      </p>
      <p className="site-footer__copyright">{`© ${new Date().getFullYear()} MedShr. All rights reserved.`}</p>

      <div className="site-footer-menu">
        <ul className="list-bare site-footer-menu__list site-footer-menu__list--pages site-footer-menu__list--legal columns-flex-10 columns-flex-12-lap">
          <li>
            <Link href="/pages/privacy">Privacy policy</Link>
          </li>
          <li>
            <Link href="/pages/terms">Terms and conditions</Link>
          </li>
        </ul>
      </div>
    </div>
    <DevMenu />
  </footer>
)
