'use client'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'
import { LargeHeader } from './_components/LargeHeader'
import { Footer } from './_components/Footer'
import '../css/index.scss'

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html style={{ scrollPaddingTop: '6rem' }} lang="en">
      <head>
        <style>{`
          body, #__next {
            height: 100%;
          }
          `}</style>
      </head>
      <body>
        <LargeHeader>
          <h2>Something went wrong</h2>
        </LargeHeader>

        <div className="page-content">
          <p>The developers have been notified and will investigate</p>
          <p>
            <button className="btn btn--primary" onClick={() => reset()}>
              Try again
            </button>
          </p>
          {error.digest ? (
            <p className="small">Error code: {error.digest}</p>
          ) : null}
        </div>
        <Footer />
      </body>
    </html>
  )
}
